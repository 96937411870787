import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(https://i.ibb.co/bH3JY30/IE1G7Lr.png);
  background-size: cover;
  background-position: center;

  z-index: 2;
  position: fixed;
  @media screen and (max-width: 997px) {
    padding: 25px;
  }
`;

export const Text = styled.p`
  font-family: 'Minecraft';
  font-size: 2.2rem;
  width: 100%;
  color: #ddd;
  text-shadow: 4px 3px #000a;
  padding: 3px;
`;

export const ServerIp = styled(Text)`
  width: fit-content;
  background-color: rgba(66, 80, 42, 0.5);
`;

export const ChatContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 70rem;
  height: 50rem;
  box-shadow: inset 4px 4px #0006, inset -4px -4px #0006;
  margin: 2rem 0;
  background-color: rgba(0, 0, 0, 0.7);
  img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    image-rendering: pixelated;
    opacity: 0.8;
    object-fit: cover;
  }
`;

export const Chat = styled.div`
  z-index: 1;
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%;
  padding: 1rem;

  p {
    width: 100%;
    word-wrap: break-word;
    font-size: 1.6rem;
  }
`;
