import styled, { css } from 'styled-components';

export const HomeContainer = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media screen and (max-width: 997px) {
    margin: 10px;
  }
`;

export const LoadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InProgressMessage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ visible }) => css`
    visibility: ${visible ? 'visible' : 'hidden'};
    height: ${visible ? `68vh` : 0};
    @media screen and (max-width: 997px) {
      height: ${visible ? `35vh` : 0};
    }
  `}

  p {
    text-align: center;
    margin-top: auto;
  }
`;

export const MovableImageContainer = styled.div`
  z-index: 1;
  width: 100%;
  position: absolute;
  @media screen and (min-width: 998px) {
    max-width: 100vh;
  }
  @media screen and (max-width: 997px) {
    max-width: 400px;
  }
`;

export const MovableImage = styled.img`
  width: 100%;
  pointer-events: none;
`;

export const MenuButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  button {
    margin-bottom: 2rem;
  }
`;
