import React from 'react';
import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom';

import { Home, Mapa, Overlay, Server } from './pages';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" element={<Home />} />
        <Route
          path="/mapa"
          element={
            <>
              <Mapa />
              <Overlay />
            </>
          }
        />
        <Route
          path="/server"
          element={
            <>
              <Server />
              <Overlay />
            </>
          }
        />
      </Switch>
    </BrowserRouter>
  );
}
