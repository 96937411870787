import React from 'react';

import minecraftClickSound from '../../../assets/minecraft-click.mp3';

import { MinecraftButton } from './styles';

export default function Button({ children, onClick, ...props }) {
  const minecraftClick = () => {
    const audio = new Audio(minecraftClickSound);
    audio.play();
  };

  return (
    <MinecraftButton
      onClick={() => {
        minecraftClick();
        onClick();
      }}
      type="button"
      {...props}
    >
      <p>{children}</p>
    </MinecraftButton>
  );
}
