import React from 'react';
import { LoaderBar, LoaderContent } from './styles';

export default function Loader() {
  return (
    <LoaderContent>
      <LoaderBar style={{ '--i': 0 }} />
      <LoaderBar style={{ '--i': 1 }} />
      <LoaderBar style={{ '--i': 2 }} />
      <LoaderBar style={{ '--i': 3 }} />
      <LoaderBar style={{ '--i': 4 }} />
      <LoaderBar style={{ '--i': 5 }} />
      <LoaderBar style={{ '--i': 6 }} />
      <LoaderBar style={{ '--i': 7 }} />
      <LoaderBar style={{ '--i': 8 }} />
      <LoaderBar style={{ '--i': 9 }} />
      <LoaderBar style={{ '--i': 10 }} />
    </LoaderContent>
  );
}
