import styled from 'styled-components';
import '@south-paw/typeface-minecraft';
// ${Text};
export const MinecraftButton = styled.button`
  height: 5.6rem;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
  font-size: calc(10px + 2vmin);
  color: white;
  background: #999 url(https://i.ibb.co/rb2TWXL/bgbtn.png) center/cover;
  image-rendering: pixelated;
  border: 3px solid #000;
  p {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset -4px -5px #0006, inset 3px 3px #fff7;
    padding: 0 2rem 5px;
    &:hover {
      background: rgba(100, 100, 255, 0.45);
      text-shadow: 3px 3px #202013cc;
      color: #ffffa0;
    }
    &:active {
      box-shadow: inset -4px -6px #0004, inset 4px 4px #fff5;
    }
  }
`;
