import React, { useEffect, useRef, useState } from 'react';
import { EmoteImg } from './styles';

export default function Emote({ onRemove, styles, imgUrl, rotation }) {
  const [loaded, setLoaded] = useState(true);
  const imgRef = useRef(null);

  useEffect(() => {
    const ref = imgRef?.current;
    if (ref) {
      ref.addEventListener('animationend', () => onRemove());
    }
    return () => {
      ref.removeEventListener('animationend', () => onRemove());
    };
  }, [onRemove]);

  return (
    <EmoteImg
      ref={imgRef}
      onLoad={() => setLoaded(true)}
      src={imgUrl}
      alt="emote"
      style={{ visibility: loaded ? 'visible' : 'hidden', ...styles }}
      rotation={rotation}
    />
  );
}
