import ayayay from '../assets/emotes/ayaya.png';
import coldDay from '../assets/emotes/cold-day.png';
import cry from '../assets/emotes/cry.png';
import fone from '../assets/emotes/fone.png';
import gun from '../assets/emotes/gun.png';
import heart1 from '../assets/emotes/heart-1.png';
import heart2 from '../assets/emotes/heart-2.png';
import normal from '../assets/emotes/normal.png';
import pizza from '../assets/emotes/pizza.png';

const emoteList = [
  ayayay,
  coldDay,
  cry,
  fone,
  gun,
  heart1,
  heart2,
  normal,
  pizza
];

export default emoteList;
