import React, { useEffect, useState } from 'react';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { AiOutlineClose } from 'react-icons/ai';

import { storage, store } from '../../store';
import { Map, PanoramicImage, ScreenLoader } from '../../components';
import { uploadFile } from '../../utils';

import { CloseModalButton, LocationModal } from './styles';

export default function Mapa() {
  const [markers, setMarkers] = useState(null);
  const [locationImageUrl, setlocationImageUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAddLocation = (locationX, locationY) => {
    const password = prompt('Palavrinha secreta');

    if (password === 'glubglub') {
      uploadFile('.png', file => {
        const storageRef = ref(storage, file.name);
        uploadBytes(storageRef, file)
          .then(() => {
            addDoc(collection(store, 'markers'), {
              name: file.name.slice(0, -4),
              locationX,
              locationY
            })
              .then(() => alert('Nova localização criada com sucesso =)'))
              .catch(error => {
                throw error;
              });
          })
          .catch(error => console.debug(error));
      });
    }
  };

  const handleShowLocation = locationName => {
    setLoading(true);
    getDownloadURL(ref(storage, `${locationName}.png`))
      .then(url => {
        setlocationImageUrl(url);
      })
      .catch(error => {
        console.debug(error);
      })
      .finally(() => setLoading(false));
  };

  const fetchMarkers = async () => {
    const querySnapshot = await getDocs(collection(store, 'markers'));
    const markersArray = [];
    querySnapshot.forEach(doc => markersArray.push(doc.data()));
    setMarkers(markersArray);
  };

  useEffect(() => {
    fetchMarkers();
  }, []);

  return (
    <div>
      <Map
        markers={markers || []}
        showLocation={handleShowLocation}
        addLocation={handleAddLocation}
      />

      {loading && <ScreenLoader />}

      {locationImageUrl && (
        <LocationModal>
          <CloseModalButton onClick={() => setlocationImageUrl('')}>
            <AiOutlineClose size={30} />
          </CloseModalButton>
          <PanoramicImage image={locationImageUrl} />
        </LocationModal>
      )}
    </div>
  );
}
