import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyDnoV1bs_nRbLdRV_WpuRphlPabZuQtfOk',
  authDomain: 'yshen-d7cb8.firebaseapp.com',
  projectId: 'yshen-d7cb8',
  storageBucket: 'yshen-d7cb8.appspot.com',
  messagingSenderId: '603656555842',
  appId: '1:603656555842:web:11fc206c73c0a04056ccdb'
};

const app = initializeApp(firebaseConfig);

const store = getFirestore(app);

const storage = getStorage(app);

export { store, storage };
