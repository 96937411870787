import React, { useEffect, useState, useRef } from 'react';
import { doc, onSnapshot, updateDoc, setDoc } from 'firebase/firestore';

import { store } from '../../store';

import { Button } from '../../components';

import { ChatContent, Container, Text, ServerIp, Chat } from './styles';

let idCounter = 0;
function uniqueKey() {
  idCounter += 1;
  return idCounter;
}

const collection = 'server';

export default function MinecraftSever() {
  const chatRef = useRef();

  const [ngrokUrl, setNgrokUrl] = useState('');
  const [logs, setLogs] = useState([]);
  const [online, setOnline] = useState(false);
  const [loading, setLoading] = useState(false);

  function startServer() {
    updateDoc(doc(store, collection, 'status'), {
      online: true,
      loading: true,
      logs: ['Abrindo server']
    }).catch(error => console.log(error));
  }

  function shutdownServer() {
    try {
      updateDoc(doc(store, collection, 'status'), {
        online: false,
        loading: true
      });
      setDoc(doc(store, collection, 'ngrok'), {
        url: ''
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const unsubNgrok = onSnapshot(doc(store, collection, 'ngrok'), ngrokDoc => {
      const { url } = ngrokDoc.data();
      if (url !== ngrokUrl) {
        setNgrokUrl(url?.replace('tcp://', ''));
      }
    });
    const unsubStatus = onSnapshot(
      doc(store, collection, 'status'),
      statusDoc => {
        const status = statusDoc.data();
        if (status.online !== online) {
          setOnline(status.online);
        }
        if (status.loading !== loading) {
          setLoading(status.loading);
        }
        setLogs(status.logs);
      }
    );
    return () => {
      unsubNgrok();
      unsubStatus();
    };
  }, [online, loading, ngrokUrl]);

  useEffect(() => {
    if (chatRef?.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
    // if (logs.slice(-1)[0]?.includes('erro')) {
    //   shutdownServer();
    // }
  }, [logs]);

  return (
    <Container>
      {online && <ServerIp>IP do server: {ngrokUrl}</ServerIp>}

      {!online && !loading && (
        <Button onClick={() => startServer()}>Abrir server</Button>
      )}

      <ChatContent>
        <img
          alt="minecraft"
          src="https://cutewallpaper.org/21/minecraft-dirt-background/Guest-Picks-Score-Points-With-a-Minecraft-Inspired-Bedroom.jpg"
        />
        <Chat ref={chatRef}>
          {logs?.map(textLog => (
            <Text key={uniqueKey()}>{textLog}</Text>
          ))}
        </Chat>
      </ChatContent>

      {online && !loading && (
        <Button onClick={() => shutdownServer()}>Fechar server</Button>
      )}
    </Container>
  );
}
