import React, { useRef, useState, useEffect } from 'react';
import Projection from 'ol/proj/Projection';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import { Point } from 'ol/geom';
import { Feature, View } from 'ol';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import OlMap from 'ol/Map';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import {
  DoubleClickZoom,
  defaults as defaultInteractions
} from 'ol/interaction';

import MapContext from './MapContext';
import mapa from '../../assets/mapa.png';
import markerImage from '../../assets/minecraft-eye.png';

import './styles.css';

const extent = [0, 0, 2004, 1404];

function Map({ markers = [], showLocation, addLocation }) {
  const mapRef = useRef();
  const [map, setMap] = useState(null);

  useEffect(() => {
    const projection = new Projection({
      code: 'xkcd-image',
      units: 'pixels',
      extent
    });

    const markerStyle = new Style({
      image: new Icon({
        src: markerImage,
        width: 30,
        height: 30
      })
    });
    const markerHoverStyle = new Style({
      image: new Icon({
        src: markerImage,
        width: 40,
        height: 40
      })
    });

    const markersFeature = markers.map(marker => {
      const markerFeature = new Feature({
        geometry: new Point([marker.locationX, marker.locationY]),
        name: marker.name,
        type: 'marker'
      });
      markerFeature.setStyle(markerStyle);

      return markerFeature;
    });

    const vectorSource = new VectorSource({
      features: markersFeature
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource
    });

    const imageLayer = new ImageLayer({
      source: new Static({
        attributions: '© <a href="https://xkcd.com/license.html%22%3Exkcd</a>',
        url: mapa,
        projection,
        imageExtent: extent
      })
    });

    const view = new View({
      projection,
      center: [1002, 702],
      zoom: 3,
      maxZoom: 8,
      extent
    });

    const doubleClickZoom = new DoubleClickZoom({
      delta: 0.1
    });

    const options = {
      view,
      layers: [imageLayer, vectorLayer],
      interactions: defaultInteractions().extend([doubleClickZoom])
    };

    const mapObject = new OlMap(options);

    mapObject.addEventListener('click', event => {
      const feature = mapObject.forEachFeatureAtPixel(event.pixel, feat => {
        return feat;
      });

      if (feature && feature.get('type') === 'marker') {
        showLocation(feature.get('name'));
      }
    });

    let hover = null;
    mapObject.on('pointermove', event => {
      if (hover !== null) {
        hover.setStyle(markerStyle);
        hover = null;
      }
      const feature = mapObject.forEachFeatureAtPixel(event.pixel, feat => {
        hover = feat;
        feat.setStyle(markerHoverStyle);
        return feat;
      });
      if (feature) {
        if (mapObject.getTargetElement().style.cursor !== 'pointer') {
          mapObject.getTargetElement().style.cursor = 'pointer';
        }
      } else if (mapObject.getTargetElement().style.cursor !== '') {
        mapObject.getTargetElement().style.cursor = '';
      }
    });

    mapObject.addEventListener('dblclick', event => {
      addLocation(
        Math.round(event.coordinate[0]),
        Math.round(event.coordinate[1])
      );
    });
    mapObject.setTarget(mapRef.current);
    setMap(mapObject);

    return () => mapObject.setTarget(undefined);
  }, [markers]);

  return (
    <MapContext.Provider value={{ map }}>
      <div ref={mapRef} className="ol-map" />
    </MapContext.Provider>
  );
}

export default Map;
