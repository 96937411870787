import React, { useEffect, useState } from 'react';

import Emote from './Emote';
import { getEmotes } from '../../services';
import { emoteList } from '../../utils';

const random = (min, max) => Math.random() * (max - min) + min;

export default function EmoteGenerator() {
  const [emotes, setEmotes] = useState([]);
  const [emotesUrls, setEmotesUrls] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      getEmotes().then(res =>
        setEmotesUrls([...emoteList, ...emoteList, ...res])
      );
    }
  }, [loaded]);

  useEffect(() => {
    if (loaded && emotes.length <= 20 && emotesUrls.length) {
      const size = Math.floor(random(20, 150));
      const position = random(1, 99);
      const duration = random(3, 10);
      const delay = random(0.1, 7);
      const rotation = random(-720, 720);

      const newEmote = {
        id: Date.now(),
        styles: {
          width: size,
          height: size,
          top: -size,
          position: 'fixed',
          left: `${position}%`,
          animationDuration: `${duration}s`,
          animationDelay: `${delay}s`,
          zIndex: '-1'
        },
        rotation: `${rotation}deg`,
        imgUrl: emotesUrls[Math.floor(random(0, emotesUrls.length))]
      };
      setEmotes(state => [...state, newEmote]);
    }
  }, [emotes.length, emotesUrls, loaded]);

  return (
    <div>
      {loaded &&
        emotes.map(({ id, ...emote }) => (
          <Emote
            key={id}
            onRemove={() => {
              setEmotes(state =>
                state.filter(filterEmote => id !== filterEmote.id)
              );
            }}
            {...emote}
          />
        ))}
    </div>
  );
}
