import styled, { keyframes } from 'styled-components';

const kaif = keyframes`  
0%
{
  height: 100%;
}
100%
{
 height: 10%;
}
`;

export const LoaderContent = styled.div`
  height: 25px;
  width: 160px;
  position: relative;
`;

export const LoaderBar = styled.span`
  background: #aaaaaa;
  height: 10%;
  width: 10px;
  position: absolute;
  left: calc(var(--i) * 15px);
  bottom: 0;
  animation: ${kaif} 0.7s ease-in-out infinite alternate-reverse;
  animation-delay: calc(var(--i) * 0.1s);
`;

export const LoaderModal = styled.div`
  z-index: 100;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;
