import styled from 'styled-components';
import { Button } from '../../components';

export const LocationModal = styled.div`
  z-index: 20;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const CloseModalButton = styled(Button)`
  z-index: 21;
  position: absolute;
  top: 1rem;
  right: 1rem;
`;
