import React from 'react';
import { Pannellum } from 'pannellum-react';

function PanoramicImage({ image }) {
  return (
    <Pannellum
      height={`${window.innerHeight}px`}
      image={image}
      pitch={6}
      yaw={180}
      hfov={90}
      autoLoad
    />
  );
}

export default PanoramicImage;
