import React from 'react';
import { useNavigate } from 'react-router-dom';

import { EmoteGenerator, Button } from '../../components';

import {
  HomeContainer,
  LoadingContent,
  MenuButton
} from './styles';

export default function Home() {
  const navigate = useNavigate();

  return (
    <HomeContainer>
        <MenuButton>
          <Button onClick={() => navigate('/server')}>Server</Button>
          <Button onClick={() => navigate('/mapa')}>Mapa</Button>
        </MenuButton>

      <EmoteGenerator />
    </HomeContainer>
  );
}
