import React from 'react';
import { BsCaretLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import { BackToMenuButton } from './styles';

export default function Overlay() {
  const navigate = useNavigate();

  return (
    <BackToMenuButton onClick={() => navigate('/')}>
      <BsCaretLeft size={30} />
    </BackToMenuButton>
  );
}
