import React from 'react';

import Loader from './index';

import { LoaderModal } from './styles';

export default function ScreenLoader() {
  return (
    <LoaderModal>
      <Loader />
    </LoaderModal>
  );
}
