import styled, { keyframes } from 'styled-components';

const emoteAnimation = rotation => keyframes`
  from {
    opacity: 0.5;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
  }
  to {
    transform: translateY(1100px) rotate(${rotation});
    opacity: 0.5;
  }
`;

export const EmoteImg = styled.img`
  position: absolute;
  animation: ${({ rotation }) => emoteAnimation(rotation)} linear;
`;
