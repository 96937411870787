import axios from 'axios';

async function getToken() {
  const clientId = 'dzbmb8coveevb27ys03xyo1jfg2wrp';
  const clientSecret = '2kgcze9gkqdi6rem63las5oudr6f2l';
  const grantType = 'client_credentials';
  return axios
    .post(
      `https://id.twitch.tv/oauth2/token?client_id=${clientId}&client_secret=${clientSecret}&grant_type=${grantType}`
    )
    .then(({ data }) => data.access_token);
}

async function getFollows(token) {
  return axios
    .get('https://api.twitch.tv/helix/users/follows?from_id=546843502', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Client-Id': 'dzbmb8coveevb27ys03xyo1jfg2wrp'
      }
    })
    .then(({ data }) => data.data);
}

async function verifySub(id, token) {
  return axios
    .get(
      `https://api.twitch.tv/helix/subscriptions/user?broadcaster_id=${id}&user_id=546843502`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Client-Id': 'dzbmb8coveevb27ys03xyo1jfg2wrp'
        }
      }
    )
    .then(({ data }) => data.data.length)
    .catch(() => {});
}

async function getEmotesUrls(id, token) {
  return axios
    .get(`https://api.twitch.tv/helix/chat/emotes?broadcaster_id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Client-Id': 'dzbmb8coveevb27ys03xyo1jfg2wrp'
      }
    })
    .then(({ data }) => data.data.map(({ images }) => images.url_4x));
}

async function getEmotes() {
  try {
    const token = await getToken();

    const follows = await getFollows(token);

    if (follows) {
      return Promise.all(
        follows.map(async follow => {
          const isSub = await verifySub(follow.to_id, token);
          if (isSub) {
            return getEmotesUrls(follow.to_id, token);
          }
          return [];
        })
      ).then(ArrayUrls => {
        return [].concat(...ArrayUrls);
      });
    }
  } catch (error) {
    console.debug('N deu pra pegar os emote =<', error);
  }
  return [];
}

export default getEmotes;
